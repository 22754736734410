@import "src/scss/variables.scss";

.date-picker-icon {
  border-radius: 0;

  &:disabled {
    .date-picker-icon__check-icon {
      opacity: 1;
    }
  }

  .date-picker-icon__check-icon {
    color: $color-theme-assist;
  }
}
