@import "src/scss/variables.scss";
@import "src/scss/mixins/text.scss";
@import "src/scss/mixins/reset.scss";

.login-page {
  background: url("~/public/main-bg/bezikaron-payments/bezikaron-payments-bg.jpg") no-repeat left
    top / cover;
  display: grid;
  grid-template-columns: 480px;
  justify-content: end;
  position: relative;
}

.login-page__container {
  background-color: $color-black-0;
  height: 100vh;
  overflow: auto;
}

.login-page__content {
  align-content: start;
  box-sizing: border-box;
  display: grid;
  gap: 60px;
  padding: 86px 40px 40px;
  width: 100%;
}

.login-page__info-container {
  display: grid;
  gap: 32px;
  position: relative;
}

.login-page__logo {
  justify-self: center;
}

.login-page__text-wrapper {
  align-items: center;
  display: grid;
  gap: 4px;
  justify-items: center;
}

.login-page__title {
  @include reset-heading();
  @include textH5($weight: $font-weight-bold);
}

.login-page__text {
  @include textBody1();
}
