@import "src/scss/variables.scss";
@import "src/scss/mixins/reset.scss";
@import "src/scss/mixins/text.scss";

.sidebar-deals {
  display: grid;
  gap: 16px;
  padding: 16px 0;
}

.sidebar-deals__title {
  @include reset-heading();
  @include textAccent($weight: $font-weight-bold, $color: $color-black-90);
  border-bottom: 1px solid $color-black-10;
  padding-bottom: 16px;
}

.sidebar-deals__header {
  background-color: $color-black-0;
  border-radius: 4px;
  display: grid;
  gap: 24px;
  grid-template-columns: $table-grid-sidebar;
  padding: 0 12px 16px;
}

.sidebar-deals__header-item {
  @include textCaption($color: $color-black-50);
}

.sidebar-deals__header-item_right {
  text-align: right;
}

.sidebar-deals__bonuses {
  border-bottom: 1px solid $color-black-10;
  border-top: 1px solid $color-black-10;
  display: grid;
  gap: 20px;
  padding: 16px 12px;
}

.sidebar-deals__bonuses-wrapper {
  display: grid;
  gap: 12px;
}

.sidebar-deals__bonuses-wrapper_editable {
  gap: 8px;
}

.sidebar-deals__comment {
  background: $color-black-6;
  border-radius: 8px;
  display: grid;
  gap: 4px;
  padding: 16px;
}

.sidebar-deals__comment-title {
  @include reset-heading();
  @include textBody($weight: $font-weight-bold, $color: $color-black-90);
}

.sidebar-deals__comment-text {
  @include reset-description();
  @include textBody();
}

.sidebar-deals__total {
  display: grid;
  gap: 24px;
  grid-template-columns: 1fr 80px;
  padding: 24px 12px;
}

.sidebar-deals__total-label {
  @include textSubtitle($weight: $font-weight-medium);
}

.sidebar-deals__total-value {
  @include textSubtitle($weight: $font-weight-bold);
  text-align: right;
}
