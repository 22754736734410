@import "src/scss/variables.scss";
@import "src/scss/mixins/text.scss";
@import "src/scss/mixins/reset.scss";

.rates-top-panel {
  align-items: center;
  border-bottom: 1px solid $color-black-10;
  box-sizing: border-box;
  display: grid;
  gap: 16px;
  grid-auto-flow: column;
  justify-content: space-between;
  padding: 12px 32px;
}

.rates-top-panel__rate-container {
  align-items: center;
  display: grid;
  gap: 12px;
  grid-template-columns: repeat(2, max-content);
}

.rates-top-panel__rate-text {
  @include reset-description();
  @include textBody($weight: $font-weight-medium);
}

.rates-top-panel__rate-actions {
  align-items: center;
  display: grid;
  gap: 12px;
  grid-template-columns: repeat(2, max-content);
  justify-items: start;
}

.rates-top-panel__rate-actions_edit {
  grid-template-columns: 120px max-content max-content;
}

.rates-top-panel__rate-price {
  @include textBody($weight: $font-weight-bold);
}

.rates-top-panel__tabs-list {
  @include reset-list();
  box-sizing: border-box;
  display: grid;
  gap: 10px;
  grid-auto-flow: column;
}

.rates-top-panel__tabs-item {
  @include reset-list-item();
}

.rates-top-panel__tab {
  grid-template-columns: none;
  min-width: 80px;
}
