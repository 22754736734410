.settings-page {
  display: flex;
  height: 100%;
  overflow: hidden;
}

.settings-page__tabs {
  flex-shrink: 0;
  height: 100%;
  position: relative;
  width: 250px;

  &::before {
    background: linear-gradient(90deg, rgba(241, 241, 241, 0.8) 0%, rgba(255, 255, 255, 0) 100%);
    content: "";
    height: 100%;
    pointer-events: none;
    position: absolute;
    right: -16px;
    top: 0;
    width: 16px;
    z-index: 20; // more than table-header
  }
}

.settings-page__content {
  flex-grow: 1;
  overflow: auto;
  position: relative;
}
