@import "src/scss/variables.scss";
@import "src/scss/mixins/text.scss";

.table-col {
  box-sizing: border-box;
  padding: 6px 8px;
}

.table-col_no-padding {
  padding: 0;
}

.table-col_with-status-button {
  .table-col__wrapper {
    justify-items: center;
    margin: -4px;
  }
}

.table-col__wrapper {
  align-items: center;
  box-sizing: border-box;
  display: grid;
}

.table-col__text {
  @include textBody();
  word-break: break-word;
}

.table-col_bold {
  .table-col__text {
    font-weight: $font-weight-bold;
  }
}

.table-col_fade {
  .table-col__text {
    color: $color-black-50;
  }
}

.table-col_caption {
  grid-area: 1/-1;

  .table-col__text {
    @include textAccent($weight: $font-weight-bold);
    line-height: 16px;
  }
}
