@import "src/scss/variables.scss";
@import "src/scss/animations.scss";

.sidebar {
  box-sizing: border-box;
  height: 100%;
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 1001;
}

.sidebar_external {
  box-shadow: $box-shadow-sidebar;
  display: grid;
  justify-content: center;
  outline: none;
  position: relative;
  width: auto;
  z-index: 0;

  .sidebar__content {
    animation: none;
    position: relative;
    transform: none;
    z-index: 0;
  }
}

.sidebar_printable {
  box-shadow: none;
}

.sidebar__shadow {
  animation: $transition-time ease-in-out forwards fadeIn;
  background-color: rgba($color: $color-black, $alpha: 0.45);
  height: 100%;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 10;
}

.sidebar__content {
  animation: $transition-time ease-in-out forwards showFromRight;
  background-color: $color-black-0;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.16);
  box-sizing: border-box;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  transform: translateX(100%);
  width: 728px;
  z-index: 100;
}

.sidebar__content_printable {
  box-shadow: none;
}

.sidebar__content-wrapper {
  align-content: start;
  box-sizing: border-box;
  display: grid;
  grid-template-rows: auto 1fr;
  height: 100%;
}

.sidebar__content-body {
  box-sizing: border-box;
  height: 100%;
  overflow: auto;
  padding: 0 32px 40px;

  @media print {
    overflow: unset;
    padding: 0;
  }
}

.sidebar_hide {
  .sidebar__content {
    animation: $transition-time ease-in-out forwards hideToRight;
  }

  .sidebar__shadow {
    animation: $transition-time ease-in-out forwards fadeOut;
  }
}

.sidebar__actions {
  align-items: center;
  display: grid;
  gap: 20px;
  grid-template-columns: 280px 280px;
  justify-content: center;
}

.sidebar__actions_single-button {
  grid-template-columns: 280px;
}
