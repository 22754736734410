@import "src/scss/variables.scss";
@import "src/scss/mixins/text.scss";

.financials {
  align-items: center;
  display: grid;
  gap: 24px;
  grid-auto-flow: column;
}

.financials__info {
  display: grid;
  gap: 6px;
  grid-auto-flow: column;
}

.financials__info-name {
  @include textBody($color: $color-black-50);
}

.financials__info-value {
  @include textBody($weight: $font-weight-bold);
}
