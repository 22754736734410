@import "src/scss/variables.scss";
@import "src/scss/mixins/focus.scss";
@import "src/scss/mixins/reset.scss";

.navbar {
  background-color: $color-theme-primary;
  display: grid;
  gap: 24px;
  grid-area: navbar;
  grid-template-rows: max-content 1fr max-content;
  justify-items: center;
  margin: 0;
  padding: 20px 0 24px;
}

.navbar__logo {
  align-items: center;
  color: $color-primary-light-basic;
  display: grid;
  height: 36px;
  justify-items: center;
  width: 36px;
}

.navbar__menu {
  @include reset-list();
  align-content: start;
  display: grid;
  gap: 20px;
}

.navbar__menu_bottom {
  padding-top: 20px;
  position: relative;

  &::before {
    background-color: $color-primary-dark-beta;
    content: "";
    height: 1px;
    left: 50%;
    position: absolute;
    transform: translateX(-50%);
    width: 20px;
  }
}
