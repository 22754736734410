/* stylelint-disable declaration-no-important */
/* stylelint-disable selector-class-pattern */
@import "src/scss/variables.scss";
@import "src/scss/mixins/focus.scss";

.flatpickr-calendar {
  background-color: $color-black-0 !important;
  border-radius: 4px !important;
  box-shadow: $box-shadow !important;
  box-sizing: border-box !important;
  width: 316px !important;

  &::before,
  &::after {
    display: none !important;
  }

  .flatpickr-months {
    padding: 18px 18px 0 !important;
  }

  .flatpickr-innerContainer {
    padding: 0 18px 18px !important;
  }
}

.flatpickr-days,
.dayContainer {
  max-width: 280px !important;
  min-width: 280px !important;
  width: 280px !important;
}

.flatpickr-weekdays {
  padding-bottom: 18px !important;
}

.flatpickr-calendar * {
  color: $color-black-75 !important;
  font-family: $font-main !important;
}

.flatpickr-weekday {
  color: $color-black-50 !important;
  font-size: 14px !important;
  font-weight: $font-weight-regular !important;
}

.flatpickr-day {
  align-items: center !important;
  border-radius: 24px;
  display: flex !important;
  font-size: 16px;
  font-weight: $font-weight-semibold !important;
  height: 40px !important;
  line-height: 20px !important;
  max-height: 40px !important;
  max-width: 40px !important;
  width: 40px !important;

  &:hover:not(.flatpickr-disabled) {
    background-color: rgba($color: $color-theme-secondary-rgb, $alpha: 0.08) !important;
    border-color: transparent !important;
  }
}

.flatpickr-day.today {
  border-color: $color-black-10 !important;

  &:hover {
    background-color: rgba($color: $color-theme-primary-rgb, $alpha: 0.08) !important;
  }
}

.flatpickr-day.selected {
  background-color: $color-theme-primary !important;
  border: none !important;
  color: $color-black-5 !important;

  &:hover {
    background-color: $color-theme-secondary !important;
  }
}

.flatpickr-day.prevMonthDay {
  height: 0 !important;
  visibility: hidden !important;
  width: 0 !important;
}

.flatpickr-day.nextMonthDay,
.flatpickr-day.flatpickr-disabled {
  color: $color-black-20 !important;
}

.flatpickr-next-month,
.flatpickr-next-month:hover,
.flatpickr-prev-month,
.flatpickr-prev-month:hover {
  color: $color-black-75 !important;

  svg {
    height: 12px !important;
    width: 8px !important;

    path {
      fill: $color-black-75 !important;
    }
  }
}

.flatpickr-next-month,
.flatpickr-prev-month {
  @include focus();
  align-items: center !important;
  border-radius: 50% !important;
  display: flex !important;
  height: 24px !important;
  justify-content: center !important;
  padding: 4px !important;
  transition: background-color $transition-time;
  width: 24px !important;
}

.flatpickr-next-month:hover,
.flatpickr-prev-month:hover {
  background-color: rgba($color: $color-theme-secondary-rgb, $alpha: 0.08) !important;
}

.flatpickr-prev-month,
.flatpickr-prev-month:hover {
  left: 40px !important;
  top: 24px !important;
}

.flatpickr-next-month,
.flatpickr-next-month:hover {
  right: 40px !important;
  top: 24px !important;
}

.flatpickr-month {
  padding-bottom: 28px !important;
}

.flatpickr-current-month {
  left: 50px !important;
  padding-top: 12px !important;
}

.flatpickr-current-month .cur-month,
.flatpickr-current-month .cur-year {
  background: none !important;
  font-size: 16px !important;
  font-weight: $font-weight-semibold !important;
  letter-spacing: 0.25px !important;
  line-height: 20px !important;
}

.flatpickr-current-month .numInputWrapper {
  background: none !important;

  .arrowUp:hover,
  .arrowDown:hover {
    background: none !important;
  }
}

.flatpickr-calendar_minified {
  .flatpickr-months,
  .flatpickr-month {
    padding: 0 !important;
  }

  .flatpickr-innerContainer {
    padding-bottom: 0 !important;
  }

  .flatpickr-current-month {
    padding-top: 10px !important;
  }

  .flatpickr-prev-month,
  .flatpickr-next-month {
    &,
    &:hover {
      top: 4px !important;
    }
  }

  .flatpickr-weekdays {
    display: none !important;
  }
}
