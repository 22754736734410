@import "src/scss/variables.scss";
@import "src/scss/mixins/reset.scss";
@import "src/scss/mixins/text.scss";

.top-panel {
  align-items: center;
  background-color: $color-black-0;
  border-bottom: 1px solid $color-borders-light;
  box-sizing: border-box;
  display: grid;
  grid-area: toppanel;
  grid-auto-flow: column;
  justify-content: space-between;
  padding: 0 32px;
}

.top-panel__intro {
  display: grid;
}

.top-panel__title {
  @include reset-heading();
  @include textAccent($weight: $font-weight-bold);
}

.top-panel__subtitle {
  @include reset-description();
  @include textCaption();
}
