@import "src/scss/variables.scss";
@import "src/scss/mixins/reset.scss";
@import "src/scss/mixins/text.scss";

.sidebar-deal {
  background-color: $color-black-0;
}

.sidebar-deal__header {
  align-items: center;
  background-color: $color-black-6;
  border-radius: 4px;
  display: grid;
  gap: 12px;
  grid-template-columns: 1fr auto;
  padding: 6px 12px;
}

.sidebar-deal__info {
  align-items: center;
  display: grid;
  gap: 12px;
  grid-auto-flow: column;
  justify-content: start;
}

.sidebar-deal__info_excluded {
  .sidebar-deal__cemetery,
  .sidebar-deal__deceased {
    color: $color-black-20;
  }
}

.sidebar-deal__crm-link {
  align-items: center;
  background-color: $color-icon-button-regular;
  border-radius: 8px;
  box-sizing: border-box;
  display: grid;
  gap: 8px;
  grid-template-columns: auto auto;
  padding: 8px;
  transition: background-color $transition-time ease-out;

  &:hover {
    background-color: $color-icon-button-hover;

    .sidebar-deal__crm-icon,
    .sidebar-deal__crm-text {
      color: $color-black-90;
    }
  }

  &:active {
    background-color: $color-icon-button-click;

    .sidebar-deal__crm-icon,
    .sidebar-deal__crm-text {
      color: $color-theme-variant;
    }
  }
}

.sidebar-deal__crm-icon {
  color: $color-black-75;
  height: 16px;
  transition: color $transition-time ease-out;
  width: 16px;
}

.sidebar-deal__crm-text {
  @include textCaption($weight: $font-weight-medium);
  transition: color $transition-time linear;
}

.sidebar-deal__cemetery,
.sidebar-deal__deceased {
  @include textBody($weight: $font-weight-medium);
}

.sidebar-deal__cemetery {
  padding-left: 13px;
  position: relative;

  &::before {
    background: $color-black-20;
    content: "";
    display: block;
    height: 16px;
    left: 0;
    position: absolute;
    top: calc(50% - 8px);
    width: 1px;
  }
}

.sidebar-deal__actions {
  align-items: center;
  display: grid;
  gap: 12px;
  grid-auto-flow: column;
}

.sidebar-deal__total {
  @include textAccent($weight: $font-weight-bold);
}

.sidebar-deal__total_excluded {
  color: $color-black-20;
}

.sidebar-deal__add-panel {
  box-sizing: border-box;
  height: 52px;
  transition: height $transition-time ease-out;
}

.sidebar-deal__add-panel_hidden {
  height: 0;
  visibility: hidden;
}

.sidebar-deal__add-panel_overflow {
  overflow: hidden;
}

.sidebar-deal__add-panel-wrapper {
  display: grid;
  gap: 16px;
  grid-template-columns: 1fr 32px;
  padding: 10px;
}

.sidebar-deal__list {
  @include reset-list();
  padding: 8px 0;
}

.sidebar-deal__list-item {
  @include reset-list-item();
  align-items: center;
  display: grid;
  gap: 24px;
  grid-template-columns: $table-grid-sidebar;
  min-height: 32px;
  padding: 8px 12px;
}

.sidebar-deal__list-item_excluded {
  .sidebar-deal__cell {
    color: $color-black-20;
    text-decoration: line-through;
  }
}

.sidebar-deal__cell {
  @include textBody();
  word-break: break-word;
}

.sidebar-deal__cell_bold {
  @include textBody($weight: $font-weight-bold);
}

.sidebar-deal__cell_right {
  text-align: right;
}

.sidebar-deal__action-button {
  justify-self: end;
}
