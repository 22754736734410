@import "src/scss/variables.scss";
@import "src/scss/mixins/text.scss";

.brigade-table {
  display: grid;
}

.brigade-table__heading {
  align-items: center;
  border-bottom: 1px solid $color-borders-light;
  display: flex;
  justify-content: space-between;
  padding: 6px 16px;
}

.brigade-table__contractors {
  @include textAccent($weight: $font-weight-bold);
}

.brigade-table__actions .table-col__wrapper {
  gap: 12px;
  grid-auto-flow: column;
  justify-content: end;
}
