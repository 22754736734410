@import "src/scss/variables.scss";
@import "src/scss/mixins/focus.scss";
@import "src/scss/mixins/reset.scss";
@import "src/scss/mixins/text.scss";

.table-button-dropdown {
  background-color: $color-black-0;
  border-radius: 16px;
  box-shadow:
    0 -1px 4px rgba(0, 0, 0, 0.04),
    0 2px 4px rgba(0, 0, 0, 0.16);
  cursor: default;
  left: 0;
  overflow: hidden;
  position: absolute;
  right: 0;
  z-index: 11;
}

.table-button-dropdown_top {
  bottom: 0;
  padding: 2px 2px 46px;
}

.table-button-dropdown_bottom {
  padding: 46px 2px 2px;
  top: 0;
}

.table-button-dropdown__actions {
  background-color: $color-black-0;
  display: grid;
  gap: 8px;
  position: relative;
  z-index: 11;
}

.table-button-dropdown__action {
  @include reset-button();
  @include focus();
  align-items: center;
  background-color: $color-black-0;
  border-radius: 16px;
  display: grid;
  gap: 8px;
  grid-template-columns: 24px auto;
  padding: 6px;
  transition: background-color $transition-time;

  &:hover,
  &:active {
    .table-button-dropdown__action-icon {
      opacity: 1;
    }
  }

  &:hover {
    background-color: $color-black-5;
  }

  &:active {
    background-color: $color-black-10;
  }
}

.table-button-dropdown__action-icon {
  align-items: center;
  background-color: $color-black-30;
  border-radius: 50%;
  color: $color-primary-light-basic;
  display: grid;
  height: 24px;
  justify-content: center;
  opacity: 0.6;
  transition: opacity $transition-time;
  width: 24px;

  svg {
    height: 16px;
    width: 16px;
  }
}

.table-button-dropdown__action-label {
  @include textBody();
}

@each $modifier, $color in $statuses-map {
  .table-button-dropdown__action_#{$modifier} {
    .table-button-dropdown__action-icon {
      background-color: $color;
    }
  }
}

.table-button-dropdown__action_disabled {
  pointer-events: none;

  .table-button-dropdown__action-icon {
    background-color: $color-black-10;
    color: $color-primary-light-basic;
  }

  .table-button-dropdown__action-label {
    color: $color-black-30;
  }
}
