@import "src/scss/variables.scss";
@import "src/scss/mixins/text.scss";

.partnership-table {
  display: grid;
  grid-template-rows: 65px auto;
  height: 100%;
}

.partnership-table__heading {
  align-items: center;
  background-color: $color-black-0;
  border-bottom: 1px solid $color-borders-light;
  display: grid;
  gap: 16px;
  grid-template-columns: 1fr max-content;
  padding: 12px 32px;
}

.partnership-table__button {
  justify-self: end;
  width: 210px;
}

.partnership-table__content {
  display: grid;
  gap: 24px;
  grid-auto-rows: min-content;
  overflow: auto;
  padding: 24px 32px;
}

.partnership-table__contractors {
  display: grid;
  gap: 12px;
  grid-template-columns: max-content max-content;
  justify-content: start;
}

.partnership-table__contractors_not-available {
  grid-template-columns: max-content max-content 1fr;
}

.partnership-table__contractors-text {
  @include textBody($weight: $font-weight-medium);
}

.partnership-table__contractors-text_bold {
  font-weight: $font-weight-bold;
}

.partnership-table__not-available-contractors {
  @include textCaption();
  justify-self: end;
}
