@import "src/scss/variables.scss";
@import "src/scss/mixins/text.scss";
@import "src/scss/mixins/reset.scss";

.filter {
  align-content: start;
  box-sizing: border-box;
  display: grid;
  gap: 20px;
}

.filter__title {
  @include reset-heading();
  @include textBody($weight: $font-weight-bold);
}
