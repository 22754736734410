@import "src/scss/variables.scss";

.print-contractor-calculation-page {
  background-color: $color-black-0;
  box-sizing: border-box;
  padding: 40px;

  @media print {
    padding: 0;
  }
}
