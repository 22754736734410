@import "src/scss/variables.scss";
@import "src/scss/mixins/text.scss";

.select {
  position: relative;
  width: 100%;
}

.select__button {
  align-content: center;
  background-color: var(--input-bg-color);
  border: var(--input-border-width) solid var(--input-border-color);
  border-radius: var(--input-regular-border-radius);
  box-sizing: border-box;
  color: var(--input-text-color);
  cursor: pointer;
  display: grid;
  font-family: $font-main;
  font-size: var(--input-regular-font-size);
  font-style: normal;
  font-weight: var(--input-font-weight);
  gap: var(--input-regular-gap);
  grid-template-columns: 1fr 20px;
  height: var(--input-regular-min-height);
  letter-spacing: var(--input-regular-letter-spacing);
  line-height: var(--input-regular-line-height);
  outline: none;
  overflow: hidden;
  padding: 0 var(--input-regular-padding-end) 0 var(--input-regular-padding-start);
  text-align: start;
  text-overflow: ellipsis;
  text-transform: var(--input-text-transform);
  transition: border-color $transition-time ease-in-out;
  white-space: nowrap;
  width: 100%;
}

.select__button_invalid {
  border-color: var(--input-invalid-border-color);

  .label,
  .select__value {
    color: var(--input-invalid-text-color);
  }

  .select__icon {
    color: var(--input-invalid-icon-color);
  }
}

.select_flattened {
  .select__button {
    align-items: center;
    border-radius: var(--input-flattened-border-radius);
    font-size: var(--input-flattened-font-size);
    gap: var(--input-flattened-gap);
    height: var(--input-flattened-min-height);
    letter-spacing: var(--input-flattened-letter-spacing);
    line-height: var(--input-flattened-line-height);
    padding-left: var(--input-flattened-padding-start);
    padding-right: var(--input-flattened-padding-end);
  }

  .select__dropbox {
    top: calc(var(--input-flattened-min-height) + 4px);
  }
}

.select_mini {
  .select__button {
    align-items: center;
    border-radius: var(--input-mini-border-radius);
    font-size: var(--input-mini-font-size);
    gap: var(--input-mini-gap);
    height: var(--input-mini-min-height);
    letter-spacing: var(--input-mini-letter-spacing);
    line-height: var(--input-mini-line-height);
    padding-left: var(--input-mini-padding-start);
    padding-right: var(--input-mini-padding-end);
  }

  .select__dropbox {
    top: calc(var(--input-mini-min-height) + 4px);
  }
}

.select__dropbox {
  background-color: $color-black-0;
  border-radius: 4px;
  box-shadow:
    0 -1px 4px rgba(0, 0, 0, 0.04),
    0 2px 4px rgba(0, 0, 0, 0.16);
  max-height: 205px;
  overflow: auto;
  padding-bottom: 8px;
  position: absolute;
  top: calc(var(--input-regular-min-height) + 4px);
  width: 100%;
  z-index: 11;
}

.select__dropbox_search {
  max-height: 216px;
}

.select__dropbox-container {
  display: grid;
}

.select__dropbox-item {
  @include textBody();
  background-color: $color-black-0;
  border: 0;
  margin: 0;
  min-height: 40px;
  outline: none;
  padding: 10px 16px;
  text-align: start;
  word-break: break-word;
}

.select__value {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.select__dropbox-item:disabled {
  color: $color-black-20;
  pointer-events: none;
}

.select__dropbox-item:not(.select__dropbox-item_active) {
  cursor: pointer;
}

.select__dropbox-item:hover,
.select__dropbox-item:focus,
.select__dropbox-item_active {
  background: $color-primary-light-basic;
}

.select__dropbox-item_empty {
  padding-bottom: 20px;
  padding-top: 20px;
}

.select .select__icon_arrow {
  transition: transform ease-in-out $transition-time;
}

.select_open .select__icon_arrow {
  transform: rotate(180deg);
}

.select:hover:not(.select_disabled) {
  .select__button {
    border-color: $color-black-50;
  }

  .select__button_invalid {
    border-color: $color-red;
  }
}

.select:focus-within:not(.select_disabled) .select__button {
  border-color: transparent;
  box-shadow: $focus-shadow;
}

.select_disabled {
  pointer-events: none;

  .select__button {
    background: var(--input-disabled-bg-color);
    border-color: var(--input-disabled-border-color);
    cursor: default;
  }

  .select__icon {
    color: var(--input-disabled-icon-color);
  }

  .select__value {
    color: var(--input-disabled-text-color);
  }

  .select__label {
    background: transparent;
    color: var(--input-disabled-label-text-color);
  }
}
