@import "src/scss/variables.scss";

@mixin focus {
  outline: none;

  &:focus:not(:disabled) {
    box-shadow: $focus-shadow;
  }

  &:focus:not(:focus-visible) {
    box-shadow: none;
  }

  &:focus-visible:not(:disabled) {
    box-shadow: $focus-shadow;
  }
}
