@import "src/scss/variables.scss";
@import "src/scss/mixins/focus.scss";
@import "src/scss/mixins/reset.scss";

.navbar-item {
  @include reset-list-item();
}

.navbar-item__link {
  @include reset-link();
  @include focus();
  align-items: center;
  border-radius: 8px;
  color: $color-primary-light-basic;
  cursor: pointer;
  display: grid;
  height: 36px;
  justify-items: center;
  transition: background-color $transition-time;
  width: 36px;

  &:hover:not(.navbar-item__link_active) {
    background-color: $color-primary-dark-alpha;
  }
}

.navbar-item__link_active {
  background-color: $color-primary-dark-beta;
  cursor: auto;

  &:hover {
    background-color: $color-primary-dark-beta;
  }
}

.navbar-item__button {
  @include reset-button();
  @include focus();
  align-items: center;
  border-radius: 8px;
  color: $color-primary-light-basic;
  cursor: pointer;
  display: grid;
  height: 36px;
  justify-items: center;
  transition: background-color $transition-time;
  width: 36px;

  &:hover:not(:disabled) {
    background-color: $color-primary-dark-alpha;
  }
}
