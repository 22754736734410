@import "src/scss/variables.scss";
@import "src/scss/mixins/focus.scss";
@import "src/scss/mixins/reset.scss";
@import "src/scss/mixins/text.scss";

.table-button {
  align-items: center;
  display: grid;
  padding: 4px;
  position: relative;

  &.table-button_disabled {
    .table-button__button {
      pointer-events: none;
    }

    .table-button__icon {
      background-color: $color-black-10;
    }

    .table-button__label {
      color: $color-black-30;
    }
  }
}

.table-button__button {
  @include reset-button();
  @include focus();
  align-items: center;
  border-radius: 16px;
  display: grid;
  gap: 8px;
  grid-template-columns: 24px 62px 16px;
  padding: 4px 8px 4px 4px;
  position: relative;
  transition: background-color $transition-time ease-out;
  z-index: 10;
}

.table-button__button_toggler-open {
  z-index: 12;
}

.table-button__badge {
  align-items: center;
  border-radius: 16px;
  display: grid;
  gap: 8px;
  grid-template-columns: 24px 86px;
  padding: 4px 8px 4px 4px;
}

.table-button__icon {
  align-items: center;
  border-radius: 50%;
  color: $color-primary-light-basic;
  display: grid;
  height: 24px;
  justify-content: center;
  width: 24px;

  svg {
    height: 16px;
    width: 16px;
  }
}

.table-button__label {
  @include textCaption($weight: $font-weight-medium);
  line-height: 24px;
}

.table-button__toggler {
  color: $color-black-75;
  height: 16px;
  width: 16px;
}

.table-button__toggler_open {
  transform: rotate(180deg);
}

@each $modifier, $color in $statuses-map {
  .table-button_#{$modifier} {
    .table-button__button,
    .table-button__badge {
      background-color: rgba($color, 0.08);
    }

    .table-button__button:hover {
      background-color: rgba($color, 0.12);
    }

    .table-button__button:active,
    .table-button__button_toggler-open {
      background-color: rgba($color, 0.2);
    }

    .table-button__icon {
      background-color: $color;
    }

    .table-button__label {
      color: $color;
    }
  }
}
