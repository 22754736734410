@import "src/scss/mixins/reset.scss";

.date-range {
  display: grid;
  gap: 20px;
  position: relative;
}

.date-range__error {
  position: static;
}

.date-range__chips-list {
  @include reset-list();
  display: grid;
  gap: 8px;
  grid-template-columns: repeat(3, 1fr);
}

.date-range__chips-list-item {
  @include reset-list-item();
  display: grid;
}
