@import "src/scss/variables.scss";
@import "src/scss/mixins/text.scss";

.bonus-line {
  align-items: center;
  display: grid;
  gap: 12px;
  grid-template-columns: 1fr 80px;
}

.bonus-line_editable {
  grid-template-columns: 1fr 80px auto;
}

.bonus-line__label {
  @include textBody($weight: $font-weight-medium);
}

.bonus-line__value {
  @include textBody($weight: $font-weight-bold);
  text-align: right;
}

.bonus-line__value_fine {
  color: $color-red;
}

.bonus-line__actions {
  display: grid;
  gap: 12px;
  grid-auto-flow: column;
}

.bonus-line__input {
  width: 120px;
}
