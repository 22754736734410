@import "src/scss/variables.scss";
@import "src/scss/mixins/text.scss";

.status {
  @include textBody($weight: $font-weight-medium);
  border-radius: 6px;
  box-sizing: border-box;
  min-width: 100px;
  padding: 2px 8px;
  text-align: center;
}

@each $modifier, $color in $sidebar-statuses-map {
  .status_#{$modifier} {
    background-color: rgba($color, 0.1);
    color: $color;
  }
}

.status_printable {
  background-color: $color-black-0;
  color: $color-black-75;
  min-width: unset;
  padding-left: 0;
  padding-right: 0;
}

@media print {
  .status {
    background-color: $color-black-0;
    color: $color-black-75;
    min-width: unset;
    padding-left: 0;
    padding-right: 0;
  }
}
