@import "../variables.scss";

@mixin text($font-family: $font-main, $size, $weight, $color: $color-text-body) {
  color: $color;
  font-family: $font-family;
  font-size: $size;
  font-weight: $weight;
  line-height: 1.2;
}

@mixin textCaption(
  $font-family: $font-main,
  $weight: $font-weight-regular,
  $color: $color-text-body
) {
  @include text($font-family: $font-family, $size: 11px, $weight: $weight, $color: $color);
  letter-spacing: 0.24px;
  line-height: 16px;
}

@mixin textBody($font-family: $font-main, $weight: $font-weight-regular, $color: $color-text-body) {
  @include text($font-family: $font-family, $size: 13px, $weight: $weight, $color: $color);
  letter-spacing: 0.25px;
  line-height: 20px;
}

@mixin textBody1(
  $font-family: $font-main,
  $weight: $font-weight-regular,
  $color: $color-text-body
) {
  @include text($font-family: $font-family, $size: 16px, $weight: $weight, $color: $color);
  letter-spacing: 0.3px;
  line-height: 24px;
}

@mixin textBody2(
  $font-family: $font-main,
  $weight: $font-weight-regular,
  $color: $color-text-body
) {
  @include text($font-family: $font-family, $size: 14px, $weight: $weight, $color: $color);
  letter-spacing: 0.25px;
  line-height: 20px;
}

@mixin textAccent(
  $font-family: $font-main,
  $weight: $font-weight-regular,
  $color: $color-text-body
) {
  @include text($font-family: $font-family, $size: 14px, $weight: $weight, $color: $color);
  letter-spacing: 0.3px;
  line-height: 24px;
}

@mixin textAssistive(
  $font-family: $font-main,
  $font-size: 13px,
  $weight: $font-weight-semibold,
  $color: $color-text-body
) {
  @include text($font-family: $font-family, $size: $font-size, $weight: $weight, $color: $color);
  letter-spacing: 0.48px;
  line-height: 20px;
}

@mixin textSubtitle(
  $font-family: $font-main,
  $font-size: 19px,
  $weight: $font-weight-regular,
  $color: $color-text-body
) {
  @include text($font-family: $font-family, $size: $font-size, $weight: $weight, $color: $color);
  letter-spacing: 0.25px;
  line-height: 28px;
}

@mixin textH1($font-family: $font-main, $weight: $font-weight-regular, $color: $color-text-body) {
  @include text($font-family: $font-family, $size: 30px, $weight: $weight, $color: $color);
  letter-spacing: 0.25px;
  line-height: 40px;
}

@mixin textH5($font-family: $font-main, $weight: $font-weight-regular, $color: $color-text-body) {
  @include text($font-family: $font-family, $size: 22px, $weight: $weight, $color: $color);
  letter-spacing: 0.25px;
  line-height: 32px;
}
