@import "src/scss/mixins/focus.scss";

.external-link {
  @include focus();
  color: inherit;
  text-decoration: none;
}

.external-link_decorated {
  text-decoration: underline;
}
