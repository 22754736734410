@import "src/scss/variables.scss";
@import "src/scss/mixins/focus.scss";
@import "src/scss/mixins/reset.scss";
@import "src/scss/mixins/text.scss";

.chip {
  @include reset-button();
  @include textCaption();
  @include focus();
  align-items: center;
  border: 1px solid $color-borders-basic;
  border-radius: 16px;
  display: inline-grid;
  max-height: 24px;
  padding: 3px 10px;
  text-align: center;
  transition: border-color $transition-time;

  &:not(.chip_disabled, .chip_active):hover {
    background-color: $color-primary-light-basic;
    border-color: $color-borders-dark;
  }

  &:not(.chip_disabled, .chip_active):active {
    background-color: $color-primary-light-alpha;
    border-color: $color-borders-dark;
  }
}

.chip_active:not(.chip_disabled) {
  background-color: $color-theme-primary;
  border-color: transparent;
  color: $color-black-5;
  pointer-events: none;
}

.chip_disabled {
  border-color: $color-black-5;
  color: $color-black-20;
  pointer-events: none;
}
