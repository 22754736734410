@import "src/scss/variables.scss";
@import "src/scss/mixins/reset.scss";
@import "src/scss/mixins/text.scss";
@import "src/scss/mixins/focus.scss";

.header-button {
  @include focus();
  @include reset-button();
  border-radius: 4px;
  height: 24px;
  position: relative;
  transition: all $transition-time;
  width: 52px;

  &:hover {
    background-color: $color-primary-light-basic;

    .header-button__label {
      border-bottom-color: transparent;
      color: $color-black-75;
    }
  }
}

.header-button__label {
  border-bottom: 1px dashed $color-borders-basic;
  color: $color-borders-basic;
  display: inline-block;
  height: 16px;
  transition: all $transition-time;
}

.header-button_arrow {
  @include textCaption($weight: $font-weight-semibold);
  background-color: $color-primary-light-basic;

  &:hover {
    background-color: $color-primary-light-alpha;
  }

  .header-button__label {
    border-bottom: 0;
    box-sizing: border-box;
    color: $color-black-75;
    display: block;
    padding: 0 10px 0 2px;
  }
}

.header-button__arrow-icon {
  color: $color-black-50;
  display: grid;
  height: 12px;
  position: absolute;
  right: 4px;
  top: 50%;
  transform: translateY(-50%);
  width: 12px;
}

.header-button__arrow-icon_sort-asc {
  transform: translateY(-50%) rotate(180deg);
}
