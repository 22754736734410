@import "src/scss/variables.scss";

.loader {
  align-items: center;
  box-sizing: border-box;
  display: flex;
  flex: 1;
  justify-content: center;
  max-width: 100vw;
  padding: 10px;
  width: 100%;
}

.loader_position_right {
  height: 100%;
  justify-content: flex-end;
  padding: 0;
  position: absolute;
  z-index: 20;

  .loader__spinner {
    height: 100%;
    width: auto;
  }
}

.loader_position_left {
  justify-content: flex-start;
}

.loader_position_overlay {
  background-color: rgba($color: $color-black-5, $alpha: 0.5);
  height: 100%;
  left: 0;
  padding: 0 0 10vh;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 20;
}

.loader_position_fixed {
  background-color: rgba($color: $color-black-5, $alpha: 0.5);
  bottom: 0;
  left: 0;
  padding: 0 0 10vh;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 20;
}

.loader__spinner {
  height: 50px;
  width: 50px;
}

.loader_size_small {
  padding: 0;

  .loader__spinner {
    height: 20px;
    width: 20px;
  }
}
