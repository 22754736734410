.main-page {
  display: flex;
  height: 100%;
  overflow: hidden;
}

.main-page__content {
  flex-grow: 1;
  overflow: auto;
  position: relative;
}
