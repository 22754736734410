@import "src/scss/variables.scss";

.rates-table {
  display: grid;
  grid-template-rows: 65px auto;
  height: 100%;
}

.rates-table__actions .table-col__wrapper {
  gap: 12px;
  grid-auto-flow: column;
  justify-content: center;
}

.rates-table__container {
  display: grid;
  height: 100%;
  overflow: auto;
}

.rates-table__add-button {
  justify-self: center;
  max-width: 76px;
}
