@import "src/scss/variables.scss";

.contractors-table__add-button {
  justify-self: center;
  max-width: 130px;
}

.contractors-table__actions .table-col__wrapper {
  gap: 12px;
  grid-auto-flow: column;
  justify-content: center;
}
