.table-body {
  display: grid;
  padding: 0 32px;

  @media (max-width: 1420px) {
    padding: 0 16px;
  }
}

.table-body_brigade {
  padding: 0;
}

.table-body_empty {
  align-items: center;
  height: 100%;
  justify-content: center;
}

.table-body_rates,
.table-body_contractors {
  padding-bottom: 24px;
}
