@import "src/scss/variables.scss";
@import "src/scss/mixins/text.scss";

.alert {
  border-radius: 0 2px 2px 0;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
  display: grid;
  gap: 8px 20px;
  grid-template-areas:
    "title close"
    "desc close";
  grid-template-columns: 1fr 16px;
  grid-template-rows: max-content max-content;
  padding: 16px;
  position: relative;
  width: 400px;
  z-index: 10;
}

.alert::before {
  border-radius: 8px 0 0 8px;
  content: "";
  display: block;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 2px;
}

.alert__title {
  @include textAccent($weight: $font-weight-semibold);
  grid-area: title;
}

.alert__desc {
  @include textAccent();
  grid-area: desc;
}

.alert__type-icon {
  grid-area: icon;
}

.alert__close-icon {
  color: $color-black-75;
  cursor: pointer;
  height: 16px;
  width: 16px;
}

.alert_icon {
  align-items: center;
  display: grid;
  gap: 8px 20px;
  grid-template-areas:
    "icon title close"
    "icon desc close";
  grid-template-columns: 20px 1fr 16px;
  grid-template-rows: max-content max-content;
}

.alert_error {
  background: $color-alert-error-background;
}

.alert_error::before {
  background: $color-alert-error;
}

.alert_error .alert__title {
  color: $color-alert-error;
}

.alert_warning {
  background: $color-alert-warning-background;
}

.alert_warning::before {
  background: $color-alert-warning;
}

.alert_warning .alert__title {
  color: $color-alert-warning;
}

.alert_info {
  background: $color-alert-info-background;
}

.alert_info::before {
  background: $color-alert-info;
}

.alert_info .alert__title {
  color: $color-alert-info;
}
