$top: 16px;

.alert-block {
  display: grid;
  gap: 12px;
  max-height: calc(100vh - $top * 2);
  overflow: auto;
  padding-right: 20px;
  position: absolute;
  right: 0;
  top: $top;
  z-index: 1100;

  @media print {
    display: none;
  }
}
