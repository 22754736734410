@import "src/scss/variables.scss";

.external-sidebar {
  align-content: start;
  background: $color-black-5;
  box-sizing: border-box;
  display: grid;
  justify-content: center;
  min-height: 100%;
  padding: 32px 0;
}
