@import "src/scss/variables.scss";
@import "src/scss/mixins/text.scss";

.select-search {
  align-items: start;
  background-color: $color-black-0;
  box-sizing: border-box;
  display: grid;
  gap: var(--input-regular-gap);
  grid-template-columns: 1fr 20px;
  height: 56px;
  padding: 16px;
  position: sticky;
  top: 0;

  &::before {
    background-color: $color-primary-light-delta;
    bottom: 8px;
    content: "";
    height: 1px;
    left: 50%;
    position: absolute;
    transform: translateX(-50%);
    transition: background-color $transition-time ease-in-out;
    width: calc(100% - 32px);
  }

  .select-search__icon_search {
    color: $color-black-30;
    transition: color $transition-time ease-in-out;
  }

  .select-search__icon_clear {
    height: 12px;
    width: 12px;
  }

  &:hover {
    .select-search__icon_search {
      color: $color-black-50;
    }

    &::before {
      background-color: $color-black-50;
    }
  }
}

.select-search__input {
  @include textBody();
  border: none;
  line-height: 20px;
  outline: none;
  padding: 0;
  width: 100%;

  &::placeholder {
    color: $color-black-50;
  }
}
