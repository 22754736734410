@import "src/scss/mixins/reset.scss";

.filters-panel {
  flex-shrink: 0;
  height: 100%;
  position: relative;
  width: 250px;

  &::before {
    background: linear-gradient(90deg, rgba(241, 241, 241, 0.8) 0%, rgba(255, 255, 255, 0) 100%);
    content: "";
    height: 100%;
    pointer-events: none;
    position: absolute;
    right: -16px;
    top: 0;
    width: 16px;
    z-index: 20; // more than table-header
  }
}

.filters-panel__statuses-list {
  @include reset-list();
  display: grid;
  gap: 20px;
}

.filters-panel__statuses-item {
  @include reset-list-item();
  align-items: center;
  display: grid;
  gap: 12px;
  grid-template-columns: 24px 1fr;
}

.filters-panel__wrapper {
  align-content: start;
  box-sizing: border-box;
  display: grid;
  gap: 28px;
  grid-template-rows: 1fr auto;
  height: 100%;
  overflow: auto;
  padding: 20px;
}

.filters-panel__main-content {
  align-content: start;
  display: grid;
  gap: 28px;
}

.filters-panel__actions {
  align-content: start;
  display: grid;
  gap: 16px;
}
