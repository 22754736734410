@import "src/scss/variables.scss";
@import "src/scss/mixins/reset.scss";
@import "src/scss/mixins/text.scss";

.table-header {
  background-color: $color-black-0;
  border-radius: 4px;
  box-sizing: border-box;
  position: sticky;
  top: 0;
  z-index: 14;
}

.table-header_main {
  .table-header__row {
    gap: 12px;
    grid-template-columns: $table-grid-main;
    padding: 16px 40px 16px 48px;
  }

  .table-header__col:not(:first-child, :last-child) {
    text-align: right;
  }
}

.table-header_contractors {
  .table-header__row {
    gap: 20px;
    grid-template-columns: $table-grid-contractors;
    padding: 16px 48px;
  }

  .table-header__col:nth-last-child(-n + 2) {
    text-align: center;
  }
}

.table-header_brigade {
  position: initial;

  .table-header__row {
    gap: 60px;
    grid-template-columns: minmax(120px, 1fr) repeat(3, minmax(140px, 0.4fr)) minmax(68px, 0.2fr);
    padding: 12px 16px;
  }
}

.table-header_rates {
  .table-header__row {
    gap: 20px;
    grid-template-columns: minmax(120px, 1.1fr) minmax(120px, 0.35fr) minmax(90px, 0.1fr) minmax(
        76px,
        0.1fr
      );
    padding: 15px 48px 16px;
  }

  .table-header__col:nth-last-child(2) {
    text-align: end;
  }

  .table-header__col:last-child {
    text-align: center;
  }
}

.table-header__row {
  align-items: center;
  box-sizing: border-box;
  display: grid;
}

.table-header__col {
  @include textCaption($color: $color-text-caption);
  padding: 0;

  &:last-of-type {
    text-align: center;
  }
}
