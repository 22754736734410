@import "src/scss/mixins/text.scss";

.deal-select-item {
  align-items: center;
  display: grid;
  gap: 2px;
  grid-template-areas:
    "title price"
    "subtitle price";
  grid-template-columns: 1fr 64px;
}

.deal-select-item__title {
  @include textBody();
  grid-area: title;
}

.deal-select-item__subtitle {
  @include textCaption($color: $color-black-50);
  grid-area: subtitle;
}

.deal-select-item__price {
  @include textBody($weight: $font-weight-bold);
  grid-area: price;
  text-align: right;
}
