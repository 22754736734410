@import "src/scss/variables.scss";
@import "src/scss/mixins/text.scss";
@import "src/scss/mixins/reset.scss";

body {
  background-color: $color-black-0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell,
    "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  text-size-adjust: none;
}

.app {
  min-height: 100vh;
}

.app_err {
  align-content: center;
  box-sizing: border-box;
  display: grid;
  justify-content: center;
  padding-bottom: 80px;
}

.app__error {
  @include textSubtitle($weight: $font-weight-bold, $color: $color-alert-error);
  @include reset-description();
}

.app__error-list {
  @include textAccent($color: $color-alert-error);
}
