@import "src/scss/variables.scss";
@import "src/scss/animations.scss";
@import "src/scss/mixins/text.scss";

.popup {
  align-items: center;
  animation: $transition-time forwards fadeIn;
  background-color: rgba($color: $color-black, $alpha: 0.45);
  box-sizing: border-box;
  display: flex;
  height: 100vh;
  justify-content: center;
  left: 0;
  opacity: 0;
  overflow: auto;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 1100;
}

.popup__wrapper {
  align-content: start;
  animation: $transition-time forwards fadeIn;
  background-color: $color-black-0;
  border-radius: 4px;
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.12);
  box-sizing: border-box;
  display: grid;
  gap: 20px;
  justify-items: center;
  min-height: auto;
  overflow: auto;
  padding: 20px;
  width: 320px;
}

.popup__header {
  display: grid;
  gap: 12px;
  width: 100%;
}

.popup__title {
  @include textAccent($weight: $font-weight-bold);
  margin: 0 auto;
  text-align: center;
}

.popup__text {
  @include textBody();
  margin: 0;
  text-align: center;
}

.popup__textarea {
  height: 160px;
  width: 100%;
}

.popup__buttons {
  display: grid;
  gap: 20px;
  grid-auto-columns: 1fr 1fr;
  grid-auto-flow: column;
  width: 100%;
}

.popup__button {
  width: 100%;
}

.popup_hiding {
  animation: $transition-time both fadeOut;

  .popup__wrapper {
    animation: $transition-time both fadeOut;
  }
}

.popup_create-team {
  .popup__wrapper {
    overflow: visible;
    width: 400px;
  }
}

.popup_warn {
  .popup__button {
    width: 134px;
  }
}
