@import "src/scss/variables.scss";
@import "src/scss/mixins/text.scss";
@import "src/scss/mixins/reset.scss";
@import "src/scss/mixins/focus.scss";

.tab {
  @include focus();
  @include reset-link();
  align-items: center;
  background-color: $color-black-0;
  border: 1px solid transparent;
  border-radius: 8px;
  box-sizing: border-box;
  display: grid;
  gap: 10px;
  grid-template-columns: max-content 1fr;
  padding: 9px 11px;
  transition:
    background-color $transition-time,
    border-color $transition-time;

  &:hover {
    background-color: $color-ghost;
    border-color: $color-ghost;
  }
}

.tab_active {
  background-color: $color-ghost;
  border: 1px solid $color-black-75;
  pointer-events: none;
}

.tab_active_semibold {
  .tab__title {
    @include textAssistive();
  }
}

.tab_active_regular {
  background-color: rgba($color-assist, 0.2);

  .tab__title {
    @include textBody($color: $color-theme-variant);
  }
}

.tab__icon {
  color: $color-black-75;
  height: 20px;
  width: 20px;
}

.tab__title {
  @include textBody();
}
