@mixin reset-button {
  background: none;
  border: 0;
  cursor: pointer;
  font: inherit;
  line-height: normal;
  margin: 0;
  outline: none;
  padding: 0;
  text-align: unset;
  text-decoration: none;
}

@mixin reset-link {
  color: inherit;
  text-decoration: none;
}

@mixin reset-list {
  list-style: none;
  margin: 0;
  padding: 0;
}

@mixin reset-list-item {
  margin: 0;
  padding: 0;
}

@mixin reset-description {
  margin: 0;
}

@mixin reset-heading {
  margin: 0;
  padding: 0;
}
