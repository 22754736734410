@import "src/scss/variables.scss";
@import "src/scss/mixins/reset.scss";
@import "src/scss/mixins/text.scss";

.sidebar-header {
  background-color: $color-black-0;
  box-sizing: border-box;
  display: grid;
  gap: 32px;
  grid-template-columns: 1fr max-content;
  padding: 32px;
}

.sidebar-header__content {
  align-items: center;
  display: grid;
  grid-auto-flow: column;
}

.sidebar-header__content_main {
  gap: 24px;
  justify-content: start;
}

.sidebar-header__content_info {
  gap: 16px;
}

.sidebar-header__title {
  @include reset-heading();
  align-items: center;
  display: grid;
  gap: 12px;
  grid-template-columns: auto auto;
  word-break: break-word;
}

.sidebar-header__title-name {
  @include textSubtitle($weight: $font-weight-bold);
  line-height: 24px;
}

.sidebar-header__title-date {
  @include textSubtitle();
  line-height: 24px;
}
