@import "src/scss/mixins/reset.scss";

.tab-list {
  @include reset-list();
  align-content: start;
  box-sizing: border-box;
  display: grid;
  gap: 8px;
  height: 100%;
  overflow: auto;
  padding: 20px;
}

.tab-list__item {
  @include reset-list-item();
}
