@import "src/scss/variables.scss";
@import "src/scss/mixins/text.scss";

.rework-button {
  justify-self: end;
  position: relative;
}

.rework-button__button {
  svg {
    color: $color-red;
    height: 17px;
    width: 17px;
  }

  @media print {
    background-color: transparent;

    svg {
      color: $color-black-75;
    }
  }
}

.rework-button__button:disabled,
.rework-button__button_disabled {
  pointer-events: none;

  svg {
    color: $color-red-light;

    @media print {
      color: $color-black-75;
    }
  }
}

.rework-button__parents {
  animation: $transition-time ease-in-out forwards fadeIn;
  background-color: $color-black-0;
  border-radius: 4px;
  box-shadow:
    0 2px 4px 0 rgba(0, 0, 0, 0.16),
    0 -1px 4px 0 rgba(0, 0, 0, 0.04);
  box-sizing: border-box;
  display: grid;
  left: -212px;
  padding: 4px 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 200px;
  z-index: 10;

  &::after {
    background-color: $color-black-0;
    border-radius: 0 0 0 30%;
    box-shadow:
      0 2px 4px 0 rgba(0, 0, 0, 0.16),
      0 -1px 4px 0 rgba(0, 0, 0, 0.04);
    clip-path: polygon(0% 0%, 100% 100%, 0% 100%);
    content: "";
    height: 10px;
    position: absolute;
    right: -5px;
    top: 50%;
    transform: translateY(-50%) rotate(225deg);
    width: 10px;
  }
}

.rework-button__parents-link {
  align-items: center;
  background-color: $color-black-0;
  box-sizing: border-box;
  display: grid;
  gap: 2px;
  grid-template-columns: minmax(150px, max-content) auto;
  height: 40px;
  justify-content: space-between;
  padding: 10px 16px;
  transition: background-color $transition-time;

  &:focus-visible:not(:disabled) {
    z-index: 1;
  }

  &:hover {
    background-color: $color-black-6;
  }
}

.rework-button__parents-link-text {
  @include textBody();
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.rework-button__parents-link-icon {
  color: $color-black-75;
  height: 16px;
  width: 16px;
}
