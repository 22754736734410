@import "../variables.scss";

.scrollbar {
  scrollbar-color: $color-black-20 $color-black-5;
  scrollbar-width: thin;
}

.scrollbar::-webkit-scrollbar-track {
  background-color: $color-black-5;
  border-radius: 3px;
}

.scrollbar::-webkit-scrollbar {
  background-color: $color-black-5;
  width: 6px;
}

.scrollbar_horizontal::-webkit-scrollbar {
  height: 6px;
}

.scrollbar::-webkit-scrollbar-thumb {
  background-color: $color-black-20;
  border-radius: 3px;
}

.no-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
    height: 0;
    width: 0;
  }
}
