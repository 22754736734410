@import "src/scss/variables.scss";
@import "src/scss/mixins/text.scss";

.table-row {
  align-items: center;
  background-color: $color-black-0;
  border-radius: 4px;
  box-sizing: border-box;
  display: grid;
  position: relative;
  transition: background-color $transition-time;

  &:nth-child(2n + 1):not(.table-row_main, .table-row_print, .table-row_brigade) {
    background-color: $color-primary-light-basic;
  }

  &:focus {
    outline: none;
  }
}

.table-row_clickable {
  background-color: $color-black-0;
  border-radius: 4px;
  cursor: pointer;
  padding: 12px 16px 12px 8px;

  &:nth-child(2n) {
    background-color: $color-primary-light-basic;
  }

  &:hover:not(.table-row_print),
  &:focus-visible:not(.table-row_print) {
    background-color: $color-primary-light-alpha;
  }
}

.table-row_main {
  background-color: transparent;
  gap: 12px;

  &:not(.table-row_clickable) {
    background-color: transparent;
    border-bottom: 1px solid $color-black-10;
    padding: 6px 16px 5px 8px;
  }

  &.table-row_clickable {
    background-color: transparent;
    grid-template-columns: 2fr repeat(4, minmax(93px, 1fr)) repeat(3, minmax(92px, 1fr)) 130px;
  }

  &.table-row_even {
    background-color: $color-primary-light-basic;
  }

  .table-col:not(:first-child, :last-child) {
    text-align: right;
  }

  &.table-row_week-title {
    border: 0;
    padding: 8px 0;

    .table-col_caption {
      background-color: $color-primary-light-alpha;
      border-radius: 6px;
      padding: 8px 16px;

      .table-col__text {
        @include textCaption($weight: $font-weight-medium);
        text-align: center;
      }
    }
  }
}

.table-row_contractors {
  gap: 20px;
  grid-template-columns: $table-grid-contractors;
  padding: 12px 16px 12px 8px;
}

.table-row_brigade {
  gap: 60px;
  grid-template-columns: minmax(120px, 1fr) repeat(3, minmax(140px, 0.4fr)) minmax(76px, 0.2fr);
  padding: 12px 16px 12px 8px;

  &:nth-child(2n) {
    background-color: $color-primary-light-basic;
  }
}

.table-row_rates {
  gap: 20px;
  grid-template-columns: minmax(120px, 1.1fr) minmax(120px, 0.35fr) minmax(90px, 0.1fr) minmax(
      76px,
      0.1fr
    );
  padding: 12px 16px 12px 8px;

  .table-col:nth-last-child(2) {
    text-align: end;

    .table-col__text {
      font-weight: $font-weight-bold;
    }
  }
}

.table-row_print {
  gap: 24px;
  grid-template-columns: $table-grid-print;
  padding: 12px;
}

.table-row__loader {
  position: absolute;
}
