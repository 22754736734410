@import "src/scss/variables.scss";
@import "src/scss/mixins/text.scss";
@import "src/scss/animations.scss";

.tooltip {
  animation: $transition-time ease-in-out forwards fadeIn;
  background-color: $color-black-50;
  border-radius: 8px;
  box-sizing: border-box;
  padding: 4px 10px;
  position: fixed;
  white-space: nowrap;
  z-index: 10000;
}

.tooltip__text {
  @include textCaption($color: $color-text-surface);
  margin: 0;
}

.tooltip_light {
  background-color: $color-primary-light-alpha;

  .tooltip__text {
    @include textBody($color: $color-text-caption);
  }
}

.tooltip_unmounting {
  animation: $transition-time ease-in-out forwards fadeOut;
}
