@import "src/scss/mixins/text.scss";

.text-error {
  @include textBody($color: $color-red-light);
  bottom: -20px;
  display: block;
  line-height: 160%;
  position: absolute;
  right: 0;
  text-align: right;
}
