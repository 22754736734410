@import "src/scss/variables.scss";
@import "src/scss/mixins/text.scss";

.table-stub {
  display: grid;
  gap: 40px;
  justify-items: center;
  padding-bottom: 10vh;
}

.table-stub__icon {
  color: $color-primary-light-delta;
}

.table-stub__info {
  display: grid;
  gap: 16px;
  padding-bottom: 8px;
  text-align: center;
}

.table-stub__title {
  @include textH5($weight: $font-weight-bold, $color: $color-black-50);
}

.table-stub__text {
  @include textAccent($font-weight-regular, $color-black-50);
  line-height: 24px;
}

.table-stub__button {
  min-width: 200px;
}
